import middle from 'data4-commons/middlewares/api/middle'

const prefix = 'DASHBOARD/'

export const GET_REQUESTS = `${prefix}GET_REQUESTS`
export const GET_DASHBOARD_DRAFT_REQUESTS = `${prefix}GET_DRAFT_REQUESTS`

export const getDashboardRequests = (filters) => middle(GET_REQUESTS, {
    api: '/api',
    url: '/demandes/demandes',
    method: 'POST',
    options: {
        body: JSON.stringify(filters)
    }
})

export const getDashboardDraftRequests = (filters) => middle(GET_DASHBOARD_DRAFT_REQUESTS, {
    api: '/api',
    url: '/draft_requests',
    method: 'POST',
    options: {
        body: JSON.stringify(filters)
    }
})

export const SEE_LAST_CONNEXION = 'SEE_LAST_CONNEXION'

/**
 * Affiche la bannière d'information à propos de la date de dernière connexion
 */
export const seeLastConnexion = () => (dispatch) => {
    dispatch({ type: SEE_LAST_CONNEXION })
}

export const HIDE_LAST_CONNEXION = 'HIDE_LAST_CONNEXION'

/**
 * Cache la bannière d'information à propos de la date de dernière connexion
 */
export const hideLastConnexionBanner = () => (dispatch) => {
    dispatch({ type: HIDE_LAST_CONNEXION })
}
